<template>
  <section class="commissioned-page">
    <h1>Commissioned</h1>

    <PhotoSwipe folder="commissioned"
                :photo-number="74" />
  </section>
</template>

<script>
export default {
  name: 'Commissioned'
}
</script>

<style lang="scss">
.commissioned-page {
}
</style>
